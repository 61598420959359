<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Productsinoperation" style="width:100vw">
  <div id="ac2129a5">
    学会投
  </div>
  <div id="f277f841">
    <div id="a4fbe861">
      <div id="a41d577c">
        已运营
      </div>
      <img src="../assets/img-afdea1b9.png" id="afdea1b9"/>
    </div>
    <div id="a3c97835">
      <div id="a2bd9784">
        最高在线人数
      </div>
      <img src="../assets/img-fe50df79.png" id="fe50df79"/>
    </div>
  </div>
  <div id="a14b97ad">
    <div id="aaced7c3">
      走势图
    </div>
    <img src="../assets/img-d82240ba.png" id="d82240ba"/>
  </div>
  <div id="f44203f4">
    系统资源
  </div>
  <div id="d193bc07">
    <div id="a5f68edb">
      <div id="a35df418">
        存储
      </div>
      <img src="../assets/img-a3e15fbf.png" id="a3e15fbf"/>
    </div>
    <div id="fad5c7af">
      <div id="a662ced9">
        流量
      </div>
      <img src="../assets/img-bf2b8817.png" id="bf2b8817"/>
    </div>
    <div id="fc30fce4">
      <div id="abd7fb20">
        短信条数
      </div>
      <img src="../assets/img-a2838482.png" id="a2838482"/>
    </div>
  </div>
  <div id="ade28c5d">
    <div id="a8b319f9">
      <div id="a321cebb">
        最高实时在线数
      </div>
      <img src="../assets/img-c7fdbdce.png" id="c7fdbdce"/>
    </div>
    <div id="c8221365">
      <div id="a356dfb4">
        注册人数
      </div>
      <img src="../assets/img-fa4b1b95.png" id="fa4b1b95"/>
    </div>
    <div id="aebacb69">
      <div id="c3be8c42">
        权限组数
      </div>
      <img src="../assets/img-ca1ab2aa.png" id="ca1ab2aa"/>
    </div>
  </div>
  <div id="bb02dc99">
    <dal-compfloat id="cmpbb02dc99">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#ac2129a5{
  padding-top:100px;
}
#ac2129a5{
  text-align:center;
  color:#1c1c1c;
  font-size:45px;
  font-family:PingFang SC;
}
#f277f841{
  width:1150px;
  position:relative;
  margin-top:60px;
  padding-left:250px;
}
#a4fbe861{
  width:507px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a41d577c{
}
#a41d577c{
  text-align:center;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#afdea1b9{
  width:507px;
  height:300px;
  margin-top:30px;
}
#afdea1b9{
  vertical-align:middle;
}
#a3c97835{
  width:508px;
  position:relative;
  margin-left:135px;
  display:inline-block;
  vertical-align: middle;
}
#a2bd9784{
}
#a2bd9784{
  text-align:center;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#fe50df79{
  width:508px;
  height:300px;
  margin-top:30px;
}
#fe50df79{
  vertical-align:middle;
}
#a14b97ad{
  width:1151px;
  position:relative;
  margin-top:60px;
  padding-left:249px;
}
#aaced7c3{
}
#aaced7c3{
  text-align:center;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#d82240ba{
  width:1151px;
  height:578px;
  margin-top:30px;
}
#f44203f4{
  margin-top:100px;
}
#f44203f4{
  text-align:center;
  color:#1c1c1c;
  font-size:45px;
  font-family:PingFang SC;
}
#d193bc07{
  width:1150px;
  position:relative;
  margin-top:60px;
  padding-left:250px;
}
#a5f68edb{
  width:263px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a35df418{
}
#a35df418{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#a3e15fbf{
  width:263px;
  height:264px;
  margin-top:30px;
}
#a3e15fbf{
  vertical-align:middle;
}
#fad5c7af{
  width:263px;
  position:relative;
  margin-left:187px;
  display:inline-block;
  vertical-align: middle;
}
#a662ced9{
}
#a662ced9{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#bf2b8817{
  width:263px;
  height:264px;
  margin-top:30px;
}
#bf2b8817{
  vertical-align:middle;
}
#fc30fce4{
  width:250px;
  position:relative;
  margin-left:187px;
  padding-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#abd7fb20{
}
#abd7fb20{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#a2838482{
  width:250px;
  height:263px;
  margin-top:30px;
}
#a2838482{
  vertical-align:middle;
}
#ade28c5d{
  width:1150px;
  position:relative;
  margin-top:51px;
  padding-left:250px;
  padding-bottom:448px;
}
#a8b319f9{
  width:263px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#a321cebb{
}
#a321cebb{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#c7fdbdce{
  width:263px;
  height:264px;
  margin-top:30px;
}
#c7fdbdce{
  vertical-align:middle;
}
#c8221365{
  width:263px;
  position:relative;
  margin-left:187px;
  display:inline-block;
  vertical-align: middle;
}
#a356dfb4{
}
#a356dfb4{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#fa4b1b95{
  width:263px;
  height:264px;
  margin-top:30px;
}
#fa4b1b95{
  vertical-align:middle;
}
#aebacb69{
  width:250px;
  position:relative;
  margin-left:187px;
  padding-bottom:1px;
  display:inline-block;
  vertical-align: middle;
}
#c3be8c42{
}
#c3be8c42{
  text-align:left;
  color:#1c1c1c;
  font-size:35px;
  font-family:PingFang SC;
}
#ca1ab2aa{
  width:250px;
  height:263px;
  margin-top:30px;
}
#ca1ab2aa{
  vertical-align:middle;
}
#bb02dc99{
  width:150px;
  position:fixed;
  z-index: 50;
  top:1446px;
  left:1740px;
}
#bb02dc99{
  background-color:rgb(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
